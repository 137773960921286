import { useStaticQuery, graphql } from "gatsby"
import { useLanguage } from "src/components/utils"
import { flatMap } from "lodash"

// Notice 카테고리 정의
const NOTICE_CATEGORY = ["ALL", "NEWS", "NOTICE"]

// 날짜 포맷팅 함수
const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}.${month}.${day}`;
}

export default function useContent() {
    const language = useLanguage()
    const languageTag = `language:${language === "ko" ? "korean" : "english"}`

    // 사전에 빌드된 페이지의 데이터를 사용합니다
    // Gatsby가 빌드 시 생성한 페이지 데이터를 사용하므로 API 호출이 필요 없습니다
    const { allSitePage } = useStaticQuery(graphql`
        query {
            allSitePage(filter: {path: {regex: "/\\/notice\\/[^\\/]+\\/$/"} }) {
                edges {
                    node {
                        path
                        context {
                            title
                            slug
                            excerpt
                            content
                            date
                            categories {
                                name
                            }
                            tags {
                                name
                                wordpress_id
                            }
                            featured_media {
                                source_url
                            }
                        }
                    }
                }
            }
        }
    `)

    // 사이트 페이지 데이터를 필터링
    const categories = NOTICE_CATEGORY

    // 언어에 맞는 데이터 필터링
    const filteredPages = allSitePage.edges
        .filter(({ node }) => {
            // 현재 경로가 현재 언어에 해당하는지 확인
            return node.path.startsWith(`/${language}/notice/`)
        })
        .map(({ node }) => {
            const post = node.context || {}
            const tags = flatMap(post.tags || [], tag => tag.name)
            const categories = flatMap(post.categories || [], category => category.name)
            const category = categories && categories.length > 0 ? categories[0] : ""
            
            return {
                ...post,
                tags,
                categories,
                category,
                date: formatDate(post.date), // 날짜 포맷팅 적용
                imgSrc: post.featured_media ? post.featured_media.source_url : null,
                excerpt: post.excerpt ? post.excerpt.replace(/<[^>]*>/g, '') : "",
                to: node.path
            }
        })

    // href 태그 처리 및 언어 필터링
    const posts = filteredPages
        .filter(post => {
            // 언어 태그 확인
            const hasLanguageTag = post.tags && post.tags.some(tag => 
                tag && (
                    tag.toLowerCase() === languageTag.toLowerCase() ||
                    tag.toLowerCase() === `language:${language.toLowerCase()}`
                )
            )
            return hasLanguageTag
        })
        .map(post => {
            // href 태그 처리
            const hrefTag = post.tags && post.tags.find(tag => tag && tag.startsWith && tag.startsWith("href:"))
            if (hrefTag) {
                const href = hrefTag.split(":").slice(1).join(":")
                return { 
                    ...post,
                    href,
                    to: undefined
                }
            }
            return post
        })

    return { categories, posts, loading: false }
}