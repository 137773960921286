import React, { useState, useEffect } from 'react'
import styled, {  } from "styled-components"
import Flex, {  } from "styled-flex-component"
import breakpoint from "styled-components-breakpoint"
import _ from "lodash"
import { P, U, ArrowButton } from 'src/components'
import { BreakLine, useDeviceName, useLanguage, useIsDeviceWidth, CapsuleText, GrayBreakLine, FadeInView } from 'src/components/utils'
import DesktopCard from './_DesktopCard'
import MobileCard from './_MobileUpdatesCard'
import useContents from "src/data"


const UpdatesRoot = styled(Flex)`
    padding: 200px 20px 0;
    box-sizing: border-box;

    ${breakpoint("mobile", "desktop")`
        padding: 100px 0;
    `}
`
const CardWrapper = styled.div`
    width: ${props => props.width};
    box-sizing: border-box;
    padding: 20px;
    transition: all 0.2s;
    opacity: ${props => props.show ? 1 : 0};
`
const CategorySelector = styled.ul`
    display: flex;
    justify-content: center;
`
const CategoryButton = styled.li`
    margin: 0 10px;
    cursor: pointer;
    font-weight: ${props => props.selected ? 900 : ""};
`
const HeadArticle = styled(Flex)`

    ${breakpoint("mobile", "desktop")`
        padding: 0 20px;
    `}
`

const columnsByDeviceName = {
    mobile: 1,
    tablet: 1,
    desktop: 2,
    "big desktop": 3,
    "big big desktop": 3
}

const Select = styled.select`
    color: ${props => props.theme.primary};
    font-size: 14px;
    font-weight: bold;
    appearance: none;
    border: none;
    background: transparent;
    border: 2px solid ${props => props.theme.secondary};
    border-radius: 5px;
    padding: 0 4px;
`

export default function Posts({ contentsName, location, navigate }) {
    const language = useLanguage()
    const isSmallDevice = useIsDeviceWidth({ from: 0, to: 800 })
    const { categories, posts } = useContents(contentsName)

    // if wrong searchParam, redirect
    useEffect(() => {
        const params = new URLSearchParams(location.search)
        const category = params.get("category")
        if (category && !categories.map(c => c.toLowerCase()).includes(category.toLowerCase())) {
            navigate(location.pathname)
        }
    }, [categories, location.pathname, location.search, navigate])

    // decide which card to use 
    const Card = isSmallDevice ? MobileCard : DesktopCard

    
    // state
    const [show, setShow] = useState(true)
    const [selectedCategory, setSelectedCategory] = useState(() => {
        const params = new URLSearchParams(location.search)
        const category = params.get("category")
        return category 
            ? categories.find(c => c.toLowerCase() === category.toLowerCase()) || categories[0]
            : categories[0]
    })

    useEffect(() => {
        const params = new URLSearchParams(location.search)
        const category = params.get("category")
        if (category) {
            const matchedCategory = categories.find(c => c.toLowerCase() === category.toLowerCase())
            if (matchedCategory) {
                setSelectedCategory(matchedCategory)
            }
        }
    }, [location.search])


    // category selector
    const mobileCategorySelectorRender = isSmallDevice
        ? <CapsuleText customCss={`margin-left: 20px; margin-bottom: 50px;`}>
            <Select onChange={e => setSelectedCategory(e.target.value)}>
                {categories.map((category, index) => (
                    <option selected={category===selectedCategory} key={index}>
                        {category}
                    </option>
                ))}
            </Select>
        </CapsuleText>
        : null

    const categorySelectorRender = isSmallDevice
        ? null
        : <CategorySelector>
            {categories.map(category => {
                const selected = category===selectedCategory

                return <CategoryButton
                key={category}
                selected={selected}
                onClick={e => {
                    setShow(false)
                    setTimeout(() => {
                        setSelectedCategory(category)
                        setShow(true)
                    }, 200)
                }}>
                    {selected
                        ? <P><U>{category}</U></P>
                        : <P>{category}</P>
                    }
                </CategoryButton>})}
        </CategorySelector>

    
    // first article is head article
    const headArticle = posts.filter(post => isSmallDevice && selectedCategory !== "ALL"
        // first article with selected category
        ? post.category === selectedCategory
        // first article in all articles
        : true
    )[0]

    const headArticleRender = headArticle 
        ?  <FadeInView threshold={0.1}>
            <HeadArticle>
                <Card
                isHeadArticle={true}
                imgSrc={headArticle.imgSrc}
                title={headArticle.title}
                content={headArticle.excerpt}
                category={headArticle.category}
                date={headArticle.date}
                buttonProps={!isSmallDevice 
                    ? { text: language==="ko" ? "자세히 보기" : "Read more", href: headArticle.href, to: headArticle.to } 
                    : null}
                href={headArticle.href}
                to={headArticle.to}/>
            </HeadArticle> 

            {isSmallDevice 
                ? <GrayBreakLine paddingTop={30} />
                : <BreakLine paddingTop={70} paddingBottom={35} />
            }

        </FadeInView>
        : null


    // decide columns
    const deviceName = useDeviceName()
    const cardWidth = 100 / columnsByDeviceName[deviceName] + "%"
    

    // post cards
    const CardsRender = <Flex wrap style={{marginTop: 40, marginBottom: 300,}}>
        {posts
            .filter(post => selectedCategory === categories[0]
                ? true
                : post.category === selectedCategory)
            .map((post, index) => {
                // first article of this category is head article
                if(index === 0 && headArticle && headArticle.category === post.category) return null

                const { imgSrc, title, excerpt, category, date, href, to } = post

                return <CardWrapper deviceName={deviceName} width={cardWidth} key={index} show={show}>
                    <FadeInView threshold={0.1}>
                        <Card
                        imgSrc={imgSrc}
                        title={title}
                        content={excerpt}
                        category={category}
                        date={date}
                        href={href}
                        to={to} />
                    </FadeInView>
                </CardWrapper>
        })}
    </Flex>


    // arrow button render 
    const arrowButtonRender = isSmallDevice
        ? <>
            <ArrowButton position={{right: 30, bottom: 70}} direction="up" onClick={e => window.scroll({ top: 0, behavior: "smooth" })} />
            <ArrowButton position={{right: 30, bottom: 20}} direction="top"  onClick={e => window.scroll({ top: document.body.clientHeight, behavior: "smooth" })} />
        </>
        : null


    return <UpdatesRoot full justifyCenter show={show}>
        <Flex column style={{width: 1000}}>
            {mobileCategorySelectorRender}
            {headArticleRender}
            {categorySelectorRender}
            {CardsRender}
        </Flex>

        {arrowButtonRender}
    </UpdatesRoot>
}